import {
  Box,
  Breakpoint,
  Container,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";
import React from "react";

type Props = {
  children?: React.ReactNode;
  bg?: string;
  maxWidth?: Breakpoint;
  sx?: SxProps<Theme>;
  direction?: ResponsiveStyleValue<
    "row" | "row-reverse" | "column" | "column-reverse"
  >;
  id?: string;
};

export default function Panel({
  children = null,
  bg = "white",
  maxWidth = "md",
  sx = {},
  direction,
  id,
}: Props) {
  return (
    <Box
      id={id}
      sx={{
        backgroundColor: bg,
        px: 4,
        py: { xs: 6, md: 10 },
        overflowY: "hidden",
        ...sx,
      }}
    >
      <Container sx={{ padding: "0!important" }} maxWidth={maxWidth}>
        <Stack direction={direction}>{children}</Stack>
      </Container>
    </Box>
  );
}
