import { ThemeOptions } from "@mui/material";

export const theme: ThemeOptions = {
  typography: {
    h1: {
      fontSize: "2.8rem",
      fontFamily: "Playfair Display",
      color: "#333333",
    },
    h2: {
      fontSize: "2.8rem",
      fontFamily: "Playfair Display",
      marginBottom: "2.0rem",
      color: "#333333",
      "&::after": {
        content: '""',
        display: "block",
        width: "3rem",
        borderBottom: "4px solid rgb(28, 140, 201)",
        position: "relative",
        bottom: "-.5rem",
        left: 0,
      },
    },
    h3: {
      fontSize: "2.2rem",
      fontFamily: "Avenir",
      marginBottom: "1.0rem",
      color: "#333333",
      "&::after": {
        content: '""',
        display: "block",
        width: "3rem",
        borderBottom: "4px solid rgb(28, 140, 201)",
        position: "relative",
        bottom: "-.3rem",
        left: "-.1rem",
      },
    },
    subtitle1: {
      fontSize: "1.2rem",
      fontFamily: "Poppins",
      letterSpacing: "0",
      color: "#333333",
    },
    h5: {
      fontFamily: "Avenir",
      fontSize: "1.4rem",
      color: "#333333",
      marginBottom: "1rem",
    },

    body1: {
      fontFamily: "Avenir",
      fontSize: "1rem",
      color: "#333333",
    },
    body2: {
      fontFamily: "Avenir",
      fontSize: "1rem",
      color: "#333333",
      lineHeight: "1.7rem",
    },
  },

  palette: {
    text: {
      primary: "#333333",
    },
    primary: {
      main: "#1a8ac7",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0",
          fonzztFamily: "Avenir",
          fontSize:"1rem",
          padding: ".5rem 1.5rem",
          shadow: "none",
          boxShadow: "none",
          textTransform: "none",
        },
      },
    },
  },
};
