import React from 'react'
import { Link, Stack, Typography } from "@mui/material";
import Panel from "../shared/Panel";

export default function Footer() {
  return (
    <Panel bg="rgb(16, 27, 43)" sx={{ py: 2 }} maxWidth="lg">
      <Stack
        direction={{ sm: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          color="white"
          fontSize=".8rem"
          textAlign={{ xs: "center", sm: "left" }}
        >
          © Boda Letícia e.v.
          <br />
          Adószám: 54986534-1-28.
        </Typography>
        <Typography
          color="white"
          fontSize=".8rem"
          textAlign={{ xs: "center", sm: "right" }}
        >
          Weboldal terv:{" "}
          <Link color="inherit" href="https://www.facebook.com/theiradesign">
            TheiraDesign
          </Link>{" "}
          (katt!)
          <br />
          Minden jog fenntartva 2023.{" "}
        </Typography>
      </Stack>
    </Panel>
  );
}
