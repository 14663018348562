import React from "react";
import { Link, Stack, styled, Typography, useTheme } from "@mui/material";
{
  /* import { useLocation } from "react-router-dom"; */
}
import { Link as NavLink } from "gatsby";
import Panel from "../shared/Panel";

const NavLine: any = styled(Stack)(() => ({
  width: "80%",
  borderTop: "1px solid #333333",
  borderBottom: "1px solid #333333",
  marginTop: "1.3rem",
}));

type NavItemProps = {
  to: string;
  path: string;
  children: React.ReactNode;
};
const NavItem = ({ to, children, path }: NavItemProps) => {
  const theme = useTheme();
  const samePage = path && (path === to || (to !== "/" && path.startsWith(to)));

  return (
    <Link
      sx={{
        lineHeight: "2.2rem",
        display: "inline-block",
        fontSize: "1.3rem",
        letterSpacing: "0",
        fontFamily: "Avenir",
        textDecoration: "none",
        cursor: "pointer",
        color: samePage ? theme.palette.primary.main : "#333333",
      }}
      component={NavLink}
      to={to}
      children={children}
    />
  );
};

export default function Nav({ path }: { path: string }) {
  return (
    <Panel id="main" sx={{ py: 5 }}>
      <Stack alignItems="center" flex="1">
        <Stack component="h1" m={0} alignItems="center">
          <Typography variant="h1" component="span" fontSize="2.5rem">
            Boda Letícia
          </Typography>
          <Typography component="span" variant="subtitle1">
            Pszichológus
          </Typography>
        </Stack>
        <NavLine
          pb={0.2}
          pt={0.6}
          direction={{ md: "row" }}
          justifyContent="space-evenly"
          alignItems="baseline"
          gap={1}
          flexWrap="wrap"
        >
          <NavItem
            to="/"
            path={path}
            // sx={{ color: "#333333" }}
          >
            Nyitólap
          </NavItem>
          <NavItem path={path} to="/rolam">
            Rólam
          </NavItem>
          <NavItem path={path} to="/tudnivalok">
            Tudnivalók és árak
          </NavItem>
          <NavItem path={path} to="/blog">
            Blog
          </NavItem>
          <NavItem path={path} to="/kapcsolat">
            Kapcsolat
          </NavItem>
        </NavLine>
      </Stack>
    </Panel>
  );
}
