exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-alkoholhasznalat-es-ondeterminacio-tsx": () => import("./../../../src/pages/blog/alkoholhasznalat-es-ondeterminacio.tsx" /* webpackChunkName: "component---src-pages-blog-alkoholhasznalat-es-ondeterminacio-tsx" */),
  "component---src-pages-blog-asszertiv-kommunikacio-tsx": () => import("./../../../src/pages/blog/asszertiv-kommunikacio.tsx" /* webpackChunkName: "component---src-pages-blog-asszertiv-kommunikacio-tsx" */),
  "component---src-pages-blog-autogen-trening-tsx": () => import("./../../../src/pages/blog/autogen-trening.tsx" /* webpackChunkName: "component---src-pages-blog-autogen-trening-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kapcsolat-tsx": () => import("./../../../src/pages/kapcsolat.tsx" /* webpackChunkName: "component---src-pages-kapcsolat-tsx" */),
  "component---src-pages-rolam-tsx": () => import("./../../../src/pages/rolam.tsx" /* webpackChunkName: "component---src-pages-rolam-tsx" */),
  "component---src-pages-tudnivalok-tsx": () => import("./../../../src/pages/tudnivalok.tsx" /* webpackChunkName: "component---src-pages-tudnivalok-tsx" */)
}

