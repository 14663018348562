import React from 'react'
import { Box, Container, Stack } from "@mui/material";
import Nav from "./Nav";
import linkedinImage from "../../images/linkedin.svg";
import instagramImage from "../../images/instagram.svg";
import Footer from "./Footer";
import { useAutoTopScroll } from "../shared/useAutoTopScroll";

export default function Layout({children, path} : React.PropsWithChildren<{path: string}>) {
  useAutoTopScroll()

  return (
    <Stack
      alignItems="stretch"
      minHeight="100vh"
      justifyContent="space-between"
    >
      <Stack alignItems="stretch">
        <Container maxWidth="xl" sx={{ position: "relative" }}>
          <Stack spacing={1} direction="row" sx={{ position: "absolute", top: 0, right: 0, p: 2 }}>
            <a href="https://www.linkedin.com/in/leticia-boda/">
              <Box
                sx={{
                  width: "2rem",
                  height: "2rem",
                  backgroundColor: "rgb(28, 140, 201)",
                  maskImage: `url(${linkedinImage})`,
                  maskSize: "stretch",
                  maskRepeat: "no-repeat",
                  maskPosition: "center",
                }}
              />
            </a>
            <a href="https://www.instagram.com/pszi.leticia/">
              <Box
                sx={{
                  width: "2rem",
                  height: "2rem",
                  backgroundColor: "rgb(28, 140, 201)",
                  maskImage: `url(${instagramImage})`,
                  maskSize: "stretch",
                  maskRepeat: "no-repeat",
                  maskPosition: "center",
                }}
              />
            </a>
          </Stack>
        </Container>
        <Nav path={path} />
        {children}
      </Stack>
      <Footer />
    </Stack>
  );
}
