import React from "react";
import "./src/styles/global.css";
import { theme } from "./src/styles/theme";
import Layout from "./src/components/layout/Layout";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const wrapPageElement = ({ element, props }) => {
  return <Layout path={props.path}>{element}</Layout>;
};

const muiTheme = createTheme(theme);
const queryClient = new QueryClient();

export const wrapRootElement = ({ element, props }) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <QueryClientProvider client={queryClient}>
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        {element}
      </QueryClientProvider>
    </ThemeProvider>
  );
};
